<template>
	<div class="content">
		<indexTop title="专项题库" :show="true"></indexTop>
		<div class="content-table">
			<div class="content-table-search">
				<div class="content-table-search-tab flex-start flex-center flex-wrap">
					<div v-for="item in examOffice" @click="selectSearch('examOffice',item.id)" :key="item.id" :class="item.id==search.examOffice?'active':''">{{item.name}}</div>
				</div>
				<div class="content-table-search-tab-two flex-start flex-center flex-wrap">
					<div v-for="item in subject" :key="item.id" @click="selectSearch('subject',item.id)" :class="item.id==search.subject?'active':''">{{item.name}}</div>
				</div>
			</div>

			<el-table :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
				<el-table-column type="selection" label="全选"></el-table-column>
				<el-table-column prop="name" label="真题试卷名称" width="600"></el-table-column>
				<el-table-column prop="examOfficeName" label="考试局"></el-table-column>
				<el-table-column prop="subjectName" label="科目"></el-table-column>
				<el-table-column prop="date" label="操作" width="100">
					<template slot-scope="scope">
						<div class="see-button flex-center flex-midden" @click="toinfo(scope.row)">
							<img src="@/assets/images/see.png" />
							<div>查阅</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="table-bottom flex-between flex-center">
				<div class="table-bottom-left flex-start flex-center">
					<div>已选择 <span>{{selectList.length}}</span> 条</div>
					<div class="flex-midden flex-center" @click="downZip">
						<img src="@/assets/images/down.png" />
						<span>下载真题试卷</span>
					</div>
				</div>


				<div class="table-bottom-right">
					<el-pagination :current-page="page" @size-change="changeSize" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="sizes, prev, pager, next, jumper" :total="total" @current-change="getNextPage">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import indexTop from "@/components/index-top.vue"
	import {
		subjectsList,
		examoffices,
		years,
		examseasons
	} from "@/api/config"
	import {
		studyFileList,
		batchDownload
	} from "@/api/index"
	export default {
		components: {
			indexTop
		},
		data() {
			return {
				tableData: [],
				page: 1,
				total: 0,
				pageSize: 10,
				search: {
					examOffice:"",
					year:"",
					subject:"",
					examSeason:"",
					type: 2,
					source:'client'
				},
				examOffice: [],
				subject: [],
				selectList:[]
				
			}
		},
		created() {
			this.getConfig();
			this.getList();
		},
		methods: {
			getConfig() {
				subjectsList().then(res => {
					this.subject = res.list;
				})
				examoffices().then(res => {
					this.examOffice = res.list;
				})
			},
			getList() {
				studyFileList({
					limit: this.pageSize,
					offset: this.page,
					...this.search
				}).then(res => {
					if (res.code == 0) {
						this.tableData = res.rows;
						this.total = res.total;
					}
				})
			},
			selectSearch(name,value){
				if(this.search[name] == value){
					this.search[name] = "";
				}else{
					this.search[name] = value;
				}
				this.getTopList();
			},
			getTopList() {
				this.page = 1;
				this.getList();
			},
			getNextPage(e) {
				this.page = e;
				this.getList();
			},
			toinfo(info) {
				window.open(info.url);
			},
			//改变条数
			changeSize(e) {
				this.pageSize = e;
				this.getTopList();
			},
			handleSelectionChange(e){
				this.selectList = e;
			},
			downZip(){
				if(this.selectList.length == 0){
					return this.$message.error("请选择记录");
				}
				let urls = [];
				this.selectList.map(item=>{
					urls.push(item.url);
				})
				batchDownload({urls:urls.join(",")}).then(res=>{
					const url = window.URL.createObjectURL(new Blob([res]))
					const link = document.createElement("a")
					link.href = url
					link.setAttribute("download",'试卷.zip')//导出的格式一定要和接口返回的文件一致
					document.body.appendChild(link)
					link.click()
					window.URL.revokeObjectURL(url)
					document.body.removeChild(link)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../assets/table.scss";
</style>