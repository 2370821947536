<template>
	<div class="login">
		<div class="login-login">
			<div class="login-model">
				<div class="login-model-text">填写资料</div>
				<el-upload :action="upload" name="file" :limit="1" :show-file-list="false" :before-upload="checkImage" :on-success="imageSuccess" style="text-align: center;">
					<div class="upload">
						<img v-if="avatar" :src="avatar"/>
						<img v-else src="@/assets/images/login/avatar.png"/>
						<div>点击可更换头像</div>
					</div>
				</el-upload>
				<el-form :model="form" :rules="rules" ref="ruleForm" label-width="0px" class="ruleForm">
					<el-form-item prop="nickname">
						<div class="select">
							<div class="input-title">
								昵称<span>*</span>
							</div>
							<el-input v-model="form.nickname" placeholder="请输入您的昵称"></el-input>
						</div>
					</el-form-item>
					<el-form-item prop="region">
						<div class="select">
							<div class="input-title">
								所在地区<span>*</span>
							</div>
							<el-select v-model="form.region" style="width: 100%;" placeholder="请选择所在地区">
								<el-option v-for="item in area" :key="item.id" :label="item.name" :value="item.id"></el-option>
							  </el-select>
						</div>
					</el-form-item>
					<el-form-item prop="school">
						<div class="select">
							<div class="input-title">
								学校<span>*</span>
							</div>
							<el-input v-model="form.school" placeholder="请输入学校"></el-input>
						</div>
					</el-form-item>
					<el-form-item prop="grade">
						<div class="select">
							<div class="input-title">
								年级<span>*</span>
							</div>
							<el-select v-model="form.grade" style="width: 100%;" placeholder="请选择年级">
								<el-option v-for="item in grade" :key="item.id" :label="item.name" :value="item.id"></el-option>
							  </el-select>
						</div>
					</el-form-item>
					<el-form-item prop="courseSystem">
						<div class="select">
							<div class="input-title">
								课程体系<span>*</span>
							</div>
							<el-select v-model="form.courseSystem" style="width: 100%;" placeholder="请选择课程体系">
								<el-option v-for="item in examofficesList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
					</el-form-item>
					<el-form-item prop="identity">
						<div class="select">
							<div class="input-title">
								角色<span>*</span>
							</div>
							<el-select v-model="form.identity" style="width: 100%;" placeholder="请选择角色">
								<el-option label="学生" value="1"></el-option>
								<el-option label="老师" value="2"></el-option>
							</el-select>
						</div>
					</el-form-item>
					<el-form-item>
						<div class="form-button">
							<div @click="tolast">取消</div>
							<div @click="submitForm('ruleForm')">完成</div>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	
	import {getUserByToken,dataFilling} from "@/api/index"
	import {uploadUrl,areaList,examoffices,gradeList} from "@/api/config"
	export default {
		data(){
			return {
				rules:{
					nickname: [
						{ required: true, message: '请输入昵称', trigger: 'blur' },
					],
					region: [
						{ required: true, message: '请选择地区', trigger: 'blur' }
					],
					school: [
						{ required: true, message: '请输入学校', trigger: 'blur' }
					],
					grade: [
						{ required: true, message: '请选择年级', trigger: 'blur' }
					],
					courseSystem: [
						{ required: true, message: '请选择课程体系', trigger: 'blur' }
					]
				},
				form:{
					nickname:"",
					region:"",
					school:"",
					courseSystem:"",
					avatar:"",
					grade:"",
					identity:"1"
				},
				upload:"",
				avatar:"",
				area:[],
				examofficesList:[],
				grade:[]
			}
		},
		mounted() {
			this.upload = uploadUrl();
			areaList().then(res=>{
				this.area = res.data;
			})
			gradeList().then(res=>{
				this.grade = res.list;
			})
			examoffices().then(res=>{
				this.examofficesList = res.list;
			})
		},
		methods:{
			//限制图片的相关格式和大小
			checkImage(file){
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg');
				const isLt2M = file.size / 1024 / 1024 < 6;
				if (!isJPG) {
				  this.$message.error('上传图片只能是JPG,JPEG,PNG格式!');
				}
				return isJPG && isLt2M;
			},
			//上传观测点的图片成功
			imageSuccess(info,e){
				if(e.response.code == 0){
					this.avatar = e.response.filePath;
					this.form.avatar = e.response.fileName;
				}else{
					this.$message.error("图片上传失败");
				}
			},
			submitForm(formName){
				if(!this.form.avatar && !this.avatar){
					return this.$message.error("请上传头像");
				}
				this.$refs[formName].validate((valid) => {
					if (valid) {
						dataFilling({...this.form,id:this.$cookies.get('userId')}).then(res=>{
							if(res.code == 0){
								this.$message.success("完善成功");
								this.$router.replace("/");
							}else{
								return this.$message.error(res.msg);
							}
						})
					} else {
						return false;
				  }
				});
			},
			tolast(){
				this.$router.replace("/");
			}
		}
	}
</script>

<style lang="scss" scoped>
	.login{
		width: 100%;
		height: 100vh;
		background-image: url(../assets/images/login/bg.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}
	.login-login{
		display: flex;
		justify-content: center;
		align-items: center;
		top: 50%;
		right: 15%;
		position: absolute;
		transform: translate(0%, -50%);
	}
	.login-model{
		width: 690px;
		background: #FFFFFF;
		border-radius: 20px;
		padding: 54px 0px 47px;
		.login-model-text{
			font-weight: bold;
			font-size: 24px;
			color: #16191E;
			line-height: 33px;
			text-align: center;
		}
		.upload{
			margin-top: 18px;
			text-align: center;
			img{
				width: 82px;
				height: 82px;
				border-radius: 100%;
				object-fit: cover;
			}
			div{
				font-weight: 400;
				font-size: 14px;
				color: #6F6F6F;
				line-height: 20px;
				margin-top: 7px;
			}
		}
	}
	.ruleForm{
		margin: 38px auto 0px;
		width: 370px;
		.pwd-see{
			width: 28px;
			height: 28px;
			position: absolute;
			top: 50%;
			margin-top: -14px;
			right: 18px;
			cursor: pointer;
		}
		.input-title{
			height: 22px;
			font-weight: 400;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			border-right: 1px solid #E3E3E3;
			width: 90px;
			text-align: right;
			position: absolute;
			top: 50%;
			left: 0px;
			margin-top: -11px;
			padding-right: 10px;
			span{
				color: #FF0000;
			}
		}
		.send-code{
			height: 22px;
			font-weight: 400;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			border-left: 1px solid #E3E3E3;
			width: 100px;
			text-align: center;
			position: absolute;
			top: 50%;
			right: 0px;
		}
	}
	::v-deep .el-input__inner{
		width: 250px;
		height: 50px;
		background: #F9F9F9;
		border-radius: 6px;
		border: 0px;
	}
	::v-deep .el-input--prefix .el-input__inner{
		padding-left: 120px;
	}
	::v-deep .el-form-item{
		margin-bottom: 18px;
	}
	.login-button{
		font-weight: 400;
		font-size: 14px;
		color: #3C4658;
		line-height: 20px;
		margin-top: 13px;
		display: flex;
		justify-content: space-between;
		div{
			cursor: pointer;
			font-weight: 400;
			font-size: 14px;
			color: #3C4658;
			line-height: 20px;
			text-align: right;
		}
	}
	.form-button{
		margin-top: 27px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		div{
			&:first-of-type{
				width: 166px;
				height: 50px;
				border-radius: 6px;
				border: 1px solid #FFB80F;
				font-weight: 400;
				font-size: 16px;
				color: #FFB80F;
				line-height: 50px;
				text-align: center;
				cursor: pointer;
			}
			&:last-of-type{
				width: 166px;
				height: 50px;
				border-radius: 6px;
				background: #FFB80F;
				border: 1px solid #FFB80F;
				font-weight: 400;
				font-size: 16px;
				color: #fff;
				line-height: 50px;
				text-align: center;
				cursor: pointer;
			}
		}
	}
	.wechat{
		text-align: center;
		margin-top: 43px;
		cursor: pointer;
		img{
			width: 55px;
			height: 55px;
		}
		div{
			font-weight: 400;
			font-size: 12px;
			color: #3C4658;
			line-height: 17px;
			margin-top: 10px;
		}
	}
	.agree{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 23px;
		cursor: pointer;
		img{
			width: 14px;
			height: 14px;
			margin-right: 8px;
		}
		div{
			font-weight: 400;
			font-size: 12px;
			color: #3C4658;
			line-height: 17px;
			a{
				color: #FFB80F;
			}
		}
	}
	.select{
		width: 370px;
		height: 50px;
		background: #F9F9F9;
		border-radius: 6px;
		padding-left: 120px;
		box-sizing: border-box;
	}
</style>