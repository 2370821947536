<template>
	<div class="usercneter">
		<infoTop></infoTop>
		<div class="center-content flex-start">
			<centerLeft :menu="6"></centerLeft>
			<div class="error">
				<div class="error-select flex-start flex-center">
					<div@click="selectSearch('examOffice','')" :class="''==search.examOffice?'active':''">全部</div>
					<div v-for="item in examOffice" @click="selectSearch('examOffice',item.id)" :key="item.id" :class="item.id==search.examOffice?'active':''">{{item.name}}</div>
				</div>
				<div class="error-select-two">
					<div@click="selectSearch('subject','')" :class="''==search.subject?'active':''">全部</div>
					<div v-for="item in subject" @click="selectSearch('subject',item.id)" :key="item.id" :class="item.id==search.subject?'active':''">{{item.name}}</div>
				</div>
				<div class="error-list flex-start flex-wrap">
					<div class="error-item" v-for="(item,index) in tableData" :key="index" @click="showInfo(index)">
						<img :src="item.imageUrl"/>
					</div>
					<div style="text-align: center;width: 100%;" v-if="page == 1 && tableData.length == 0">
						<el-empty description="暂无数据"></el-empty>
					</div>
				</div>
				<div class="table-bottom-right flex-end">
					<el-pagination :current-page="page" :page-size="pageSize" layout="prev, pager, next, jumper" :total="total" @current-change="getNextPage">
					</el-pagination>
				</div>
			</div>
		</div>
		
		<el-dialog title="" :visible.sync="dialogVisible" width="840px" custom-class="addModel" :center="true" :append-to-body="true" :show-close="false" :close-on-click-modal="true">
		 <div class="showinfo" v-if="dialogVisible">
			 <img class="showinfo-title" :src="tableData[selectIndex].imageUrl"/>
			 <div class="result-list-item-info flex-start" v-if="tableData[selectIndex].isViewAnswers == 1 && tableData[selectIndex].questionType == 1">
			 	<div>题目答案</div>
			 	<img :src="tableData[selectIndex].answe"/>
			 </div>
			 <div class="result-list-item-info flex-start" v-if="tableData[selectIndex].isViewAnswers == 1 && tableData[selectIndex].questionType != 1">
			 	<div>题目答案</div>
			 	<img :src="tableData[selectIndex].answerUrl"/>
			 </div>
			 <div class="result-list-item-info flex-start" v-if="tableData[selectIndex].isViewAnswers == 1 && tableData[selectIndex].videoUrl">
			 	<div>视频讲解</div>
			 	<video :src="tableData[selectIndex].videoUrl" controls/>
			 </div>
		 </div>
		  <span slot="footer" class="dialog-footer flex-midden flex-center" v-if="dialogVisible">
			  <div class="bottom-one" @click="lastquestion">上一题</div>
			  <div class="bottom-two" @click="seePdf">查看原题文档</div>
			  <div class="bottom-two" v-if="tableData[selectIndex].isViewAnswers == 0" @click="seeAnswer">查看原题答案</div>
			  <div class="bottom-one" @click="nextquestion">下一题</div>
		  </span>
		</el-dialog>
		<score ref="scoreRef" @ok="scoreSubmit"></score>
	</div>
</template>

<script>
	import centerLeft from "@/components/center-left.vue"
	import {getWrongQuestion} from "@/api/me"
	import {pointsRuleKey,getUserByToken,viewAnswers} from "@/api/index"
	import infoTop from "@/components/info-top.vue"
	import {subjectsList,examoffices} from "@/api/config"
	import score from "@/components/score.vue"
	export default {
		components:{
			centerLeft,
			infoTop,
			score
		},
		data() {
			return {
				tableData: [],
				page: 1,
				total: 0,
				pageSize: 6,
				search: {
					examOffice:"",
					year:"",
					subject:"",
					examSeason:"",
					type: 5,
					source:'client'
				},
				examOffice: [],
				subject: [],
				dialogVisible:false,
				selectIndex:0,
				seescore:0
				
			}
		},
		created() {
			this.getConfig();
			this.getList();
			pointsRuleKey("question_answers").then(res=>{
				this.seescore = res.data.points < 0 ?res.data.points*-1:res.data.points;
			})
		},
		methods: {
			getConfig() {
				subjectsList().then(res => {
					this.subject = res.list;
				})
				examoffices().then(res => {
					this.examOffice = res.list;
				})
			},
			getList() {
				getWrongQuestion({
					limit: this.pageSize,
					offset: this.page,
					...this.search
				}).then(res => {
					if (res.code == 0) {
						this.tableData = res.rows;
						this.total = res.total;
					}
				})
			},
			selectSearch(name,value){
				if(this.search[name] == value){
					this.search[name] = "";
				}else{
					this.search[name] = value;
				}
				this.$forceUpdate();
				this.getTopList();
			},
			getTopList() {
				this.page = 1;
				this.getList();
			},
			getNextPage(e) {
				this.page = e;
				this.getList();
			},
			toinfo(info) {
				window.open(info.url);
			},
			//改变条数
			changeSize(e) {
				this.pageSize = e;
				this.getTopList();
			},
			showInfo(index){
				this.selectIndex = index;
				this.dialogVisible = true;
			},
			lastquestion(){
				if(this.selectIndex <= 0){
					this.selectIndex = 0;
				}else{
					this.selectIndex--;
				}
			},
			nextquestion(){
				if(this.selectIndex + 1 >= this.tableData.length){
					this.selectIndex = this.tableData.length-1;
				}else{
					this.selectIndex++;
				}
			},
			//查看答案
			seeAnswer(){
				getUserByToken().then(res=>{
					if(res.code == 0){
						if(res.data.points >= this.seescore){
							this.$refs.scoreRef.init(1,"查看答案需要"+this.seescore+"积分，是否查看",{index:this.selectIndex,type:1});
						}else{
							this.$refs.scoreRef.init(2,"当前积分不足请充值或购买会员",{});
						}
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			//查看文档
			seePdf(){
				if(this.tableData[this.selectIndex].isViewAnswers == 1){
					window.open(this.tableData[this.selectIndex].sourceUrl);
				}else{
					getUserByToken().then(res=>{
						if(res.code == 0){
							if(res.data.points >= this.seescore){
								this.$refs.scoreRef.init(1,"查看原题文档需要"+this.seescore+"积分，是否查看",{index:this.selectIndex,type:2});
							}else{
								this.$refs.scoreRef.init(2,"当前积分不足请充值或购买会员",{});
							}
						}else{
							this.$message.error(res.msg);
						}
					})
				}
				
			},
			//积分花费确认
			scoreSubmit(e){
				if(e.type == 1){//确认查看答案
					viewAnswers({
						paperId:this.tableData[e.index].newPaperId,
						questionId:this.tableData[e.index].questionId,
						ruleKey:"question_answers"
					}).then(res=>{
						if(res.code == 0){
							this.tableData[e.index].isViewAnswers = 1;
							this.tableData[e.index].answerUrl = res.data.answerUrl;
							this.tableData[e.index].sourceUrl = res.data.sourceUrl;
							this.tableData[e.index].videoUrl = res.data.videoUrl;
						}else{
							this.$message.error(res.msg);
						}
						
					})
				}else if(e.type == 2){//查看报告
					viewAnswers({
						paperId:this.tableData[e.index].newPaperId,
						questionId:this.tableData[e.index].questionId,
						ruleKey:"question_answers"
					}).then(res=>{
						if(res.code == 0){
							this.tableData[e.index].isViewAnswers = 1;
							this.tableData[e.index].answerUrl = res.data.answerUrl;
							this.tableData[e.index].sourceUrl = res.data.sourceUrl;
							this.tableData[e.index].videoUrl = res.data.videoUrl;
							window.open(this.tableData[this.selectIndex].sourceUrl);
						}else{
							this.$message.error(res.msg);
						}
						
					})
				}
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	@import "./index.scss";
	@import "../../assets/table.scss";
	.showinfo{
		background: #FFFFFF;
		width: 600px;
		margin: 0 auto;
		.showinfo-title{
			width: 600px;
			height: auto;
			margin: 0 auto;
			display: block;
		}
		.result-list-item-info{
			margin-top: 27px;
			div{
				font-weight: bold;
				font-size: 14px;
				color: #16191E;
				line-height: 20px;
				text-align: left;
				margin-right: 14px;
				flex: 0 0 60px;
			}
			img{
				width: 300px;
				height: auto;
			}
			video{
				width: 300px;
				height: auto;
			}
			.result-list-item-info-content{
				width: 819px;
				flex: 0 0 819px;
				background: #F6F6F6;
				border-radius: 4px;
				border: 1px solid #F1F1F1;
				padding: 17px;
				box-sizing: border-box;
				font-weight: 400;
				font-size: 14px;
				color: #777C85;
				line-height: 20px;
				text-align: left;
			}
			.result-list-item-info-score{
				height: 20px;
				font-weight: 500;
				font-size: 14px;
				color: #FF5858;
				line-height: 20px;
				text-align: left;
				flex: 0 0 200px;
			}
			.result-list-item-list{
				width: 819px;
				flex: 0 0 819px;
				img{
					width: 92px;
					height: 92px;
					margin-right: 12px;
					margin-bottom: 12px;
					object-fit: contain;
					border-radius: 8px;
					border: 1px solid #E7E7E7;
					box-sizing: border-box;
					cursor: pointer;
				}
			}
		}
	}
	.dialog-footer{
		margin-top: 50px;
	}
	.bottom-one{
		width: 75px;
		height: 34px;
		border-radius: 4px;
		border: 1px solid #FFB80F;
		font-weight: 400;
		font-size: 14px;
		color: #FFB80F;
		line-height: 34px;
		text-align: center;
		margin: 0px 11px;
		cursor: pointer;
	}
	.bottom-two{
		width: 122px;
		height: 34px;
		background: #FFB80F;
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 34px;
		text-align: center;
		margin: 0px 11px;
		cursor: pointer;
	}
	.error{
		width: 941px;
		background-color: #fff;
		padding: 50px 30px;
		box-sizing: border-box;
		.error-list{
			height: 570px;
			.error-item{
				margin: 0px 18px 18px 0px;
				img{
					width: 264px;
					height: 264px;
					object-fit: contain;
					cursor: pointer;
				}
			}
		}
	}
	.error-select{
		margin-bottom: 16px;
		overflow-x: auto;
		div{
			height: 34px;
			background: #F8F8F8;
			border-radius: 8px;
			font-weight: 400;
			font-size: 16px;
			color: #7C7C7C;
			line-height: 34px;
			text-align: center;
			margin-right: 20px;
			display: inline-block;
			width: 130px;
			cursor: pointer;
		}
		.active{
			background: #FFB80F;
			color: #fff;
		}
	}
	.error-select-two{
		margin-bottom: 16px;
		overflow-x: auto;
		div{
			width: 130px;
			height: 34px;
			background: #fff;
			border-radius: 8px;
			font-weight: 400;
			font-size: 16px;
			color: #7C7C7C;
			line-height: 34px;
			text-align: center;
			margin-right: 20px;
			display: inline-block;
			border:1px solid #EEEEEE;
			cursor: pointer;
		}
		.active{
			border:1px solid #FFB80F;
			background: rgba(255, 184, 15, 0.1);
			color: #FFB80F;
		}
	}
	
	::v-deep .addModel{
		border-radius: 10px !important;
	}
	::v-deep .el-dialog__title{
		height: 0px;
	}
	::v-deep .el-dialog--center .el-dialog__body{
		padding: 0px;
	}
</style>