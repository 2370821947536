<template>
	<div class="usercneter">
		<infoTop></infoTop>
		<div class="center-content flex-start">
			<centerLeft :menu="2"></centerLeft>
			<div class="report">
				<div class="content-table-search">
					<div class="content-table-search-value flex-start flex-center flex-wrap">
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">科目</div>
							<el-select v-model="search.subject" placeholder="请选择科目" clearable size="mini">
								<el-option v-for="item in subject" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">创建时间</div>
							<el-date-picker v-model="search.date" style="width: 100%;" size="mini" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">知识点</div>
							<el-cascader v-model="search.knowled" :options="knowList" size="mini" style="width: 100%;" :show-all-levels="false" :props="{multiple: true,checkStrictly: true,value:'id',label:'name',children:'children'}" collapse-tags clearable></el-cascader>
							
						</div>
						<div class="content-table-search-value-button flex-start flex-center">
							<div @click="tosearch(1)">老师判卷</div>
							<div @click="tosearch(3)">自我判卷</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import infoTop from "@/components/info-top.vue"
	import centerLeft from "@/components/center-left.vue"
	import {subjectsList} from "@/api/config"
	import {getKnowledgeList,getReportList} from "@/api/index"
	export default {
		components:{
			infoTop,
			centerLeft
		},
		data(){
			return {
				search:{
				  subject:"",
				  date:[],
				  knowled:[]
				},
				subject:[],
				knowList:[]
			}
		},
		mounted() {
			subjectsList().then(res => {
				this.subject = res.list;
			})
			getKnowledgeList({ids:''}).then(res => {
				this.knowList = res.data;
			})
		},
		methods:{
			tosearch(status){
				let value = {
					knowledgeId1:[],
					knowledgeId2:[],
					knowledgeId3:[]
				};
				this.search.knowled.map(item=>{
					if(item.length >= 1){
						value.knowledgeId1.push(item[0]);
					}
					if(item.length >= 2){
						value.knowledgeId2.push(item[1]);
					}
					if(item.length >= 3){
						value.knowledgeId3.push(item[2]);
					}
				})
				getReportList({
					subject:this.search.subject,
					knowledgeId1:value.knowledgeId1.join(","),
					knowledgeId2:value.knowledgeId2.join(","),
					knowledgeId3:value.knowledgeId3.join(","),
					startTime:this.search.date.length == 2?this.search.date[0]:"",
					endTime:this.search.date.length == 2?this.search.date[1]:"",
					status:status
				}).then(res=>{
					
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "./index.scss";
	
	.report{
		width: 941px;
		background-color: #fff;
		padding: 50px 30px;
		box-sizing: border-box;
		.error-list{
			height: 570px;
		}
	}
	.content-table-search{
		.content-table-search-tab{
			padding: 0px 9px;
			box-sizing: border-box;
			div{
				width: 106px;
				height: 37px;
				background: #EFEFEF;
				border-radius: 8px;
				font-weight: 400;
				font-size: 14px;
				color: #16191E;
				line-height: 37px;
				text-align: center;
				margin-right: 14px;
				margin-bottom: 17px;
				cursor: pointer;
			}
			.active{
				background-color: #FFB80F;
				color: #fff;
			}
		}
		.content-table-search-tab-two{
			padding: 0px 9px;
			box-sizing: border-box;
			div{
				width: 106px;
				height: 37px;
				background: #fff;
				border-radius: 8px;
				font-weight: 400;
				font-size: 14px;
				color: #16191E;
				line-height: 35px;
				border: 1px solid #F3F3F3;
				text-align: center;
				margin-right: 14px;
				margin-bottom: 17px;
				box-sizing: border-box;
				cursor: pointer;
			}
			.active{
				background: rgba(255,184,15,0.1);
				border: 1px solid rgba(255,184,15,0.1);
				color: #FFB80F;
			}
		}
		.content-table-search-value{
			margin-top: 14px;
			.content-table-search-value-item{
				margin-right: 40px;
				margin-bottom: 16px;
				.content-table-search-value-title{
					font-weight: 400;
					font-size: 14px;
					color: #16191E;
					line-height: 20px;
					margin-right: 12px;
					width: 60px;
					text-align: right;
					flex: 0 0 60px;
				}
			}
		}
		.content-table-search-form{
			margin-top: 14px;
			margin-bottom: 21px;
			.content-table-search-form-item{
				margin-right: 50px;
				.content-table-search-form-title{
					font-weight: 400;
					font-size: 14px;
					color: #16191E;
					line-height: 20px;
					margin-right: 12px;
				}
				.content-table-search-form-check{
					margin: 0px 12px;
					cursor: pointer;
					img{
						width: 14px;
						height: 14px;
					}
					div{
						font-weight: 400;
						font-size: 12px;
						color: #3C4658;
						line-height: 17px;
						margin-left: 10px;
					}
				}
			}
		}
	}
	.content-table-search-value-button{
		margin-bottom: 16px;
		div{
			&:first-of-type{
				width: 75px;
				height: 32px;
				border-radius: 4px;
				border: 1px solid #FFB80F;
				font-weight: 400;
				font-size: 14px;
				color: #FFB80F;
				line-height: 32px;
				text-align: center;
				margin-right: 20px;
				cursor: pointer;
			}
			&:last-of-type{
				width: 75px;
				height: 32px;
				border-radius: 4px;
				border: 1px solid #FFB80F;
				background-color: #FFB80F;
				font-weight: 400;
				font-size: 14px;
				color: #fff;
				line-height: 32px;
				text-align: center;
				cursor: pointer;
				
			}
		}
	}
</style>