<template>
	<div class="login">
		<div class="login-login">
			<div class="login-login-bg">
				<img src="@/assets/images/index/bg-icon.png"/>
			</div>
			<div class="login-model">
				<div class="login-model-text">忘记密码</div>
				<div class="forget-select">
					<div @click="type=1" :class="type==1?'active':''">手机号码<span></span></div>
					<div @click="type=2" :class="type==2?'active':''">邮箱<span></span></div>
				</div>
				<el-form v-if="type == 1" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="ruleForm">
					<el-form-item prop="no">
						<el-input v-model="ruleForm.no" placeholder="请输入您的手机号码"></el-input>
					</el-form-item>
					<el-form-item prop="code">
						<el-input v-model="ruleForm.code" placeholder="请输入验证码">
							<div slot="suffix" class="send-code" v-if="send">{{time}}S</div>
							<div slot="suffix" class="send-code" v-else @click="sendCode">获取验证码</div>
						</el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input :type="showpwd?'text':'password'" v-model="ruleForm.password" placeholder="请输入密码">
							<img v-if="showpwd" @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/open.png"/>
							<img v-else @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/see.png"/>
						</el-input>
					</el-form-item>
					<el-form-item prop="passwordTwo">
						<el-input :type="showpwd?'text':'password'" v-model="ruleForm.passwordTwo" placeholder="请再次输入确认密码">
							<img v-if="showpwd" @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/open.png"/>
							<img v-else @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/see.png"/>
						</el-input>
					</el-form-item>
					<el-form-item>
						<div class="form-button">
							<div @click="tolast">取消</div>
							<div @click="submitForm('ruleForm')">确定</div>
						</div>
					</el-form-item>
				</el-form>
				
				
				<el-form v-if="type == 2" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="ruleForm">
					<el-form-item prop="no">
						<el-input v-model="ruleForm.no" placeholder="请输入邮箱"></el-input>
					</el-form-item>
					<el-form-item prop="code">
						<el-input v-model="ruleForm.code" placeholder="请输入验证码">
							<div slot="suffix" class="send-code" v-if="send">{{time}}S</div>
							<div slot="suffix" class="send-code" v-else @click="sendCode">获取验证码</div>
						</el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input :type="showpwd?'text':'password'" v-model="ruleForm.password" placeholder="请输入密码">
							<img v-if="showpwd" @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/open.png"/>
							<img v-else @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/see.png"/>
						</el-input>
					</el-form-item>
					<el-form-item prop="passwordTwo">
						<el-input :type="showpwd?'text':'password'" v-model="ruleForm.passwordTwo" placeholder="请再次输入确认密码">
							<img v-if="showpwd" @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/open.png"/>
							<img v-else @click="showpwd = !showpwd" slot="suffix" class="pwd-see" src="@/assets/images/login/see.png"/>
						</el-input>
					</el-form-item>
					<el-form-item>
						<div class="form-button">
							<div @click="tolast">取消</div>
							<div @click="submitForm('ruleForm')">确定</div>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {sendSms,sendEmail,forgotPassword} from "@/api/index"
	export default {
		data(){
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
				  callback(new Error('请再次输入密码'));
				} else if (value !== this.ruleForm.password) {
				  callback(new Error('两次输入密码不一致!'));
				} else {
				  callback();
				}
			  };
			return {
				ruleForm:{
					id:"",
					no:"",
					password:"",
					code:"",
					passwordTwo:""
				},
				rules:{
					no: [
						{ required: true, message: '请输入', trigger: 'blur' },
					],
					code: [
						{ required: true, message: '请输入验证码', trigger: 'blur' }
					],
					password: [
						{ required: true, message: '请输入密码', trigger: 'blur' }
					],
					passwordTwo: [
						{ validator: validatePass2, trigger: 'blur' }
					]
				},
				showpwd:false,//是否展示密码
				type:1,
				send:false,
				time:60,
				timeFun:null
			}
		},
		methods:{
			sendCode(){
				if(this.send){return false;}
				if(this.type == 1){//手机号验证
					if(this.ruleForm.no == ""){
						return this.$message.error("请输入手机号");
					}
					let reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
					if(!reg.test(this.ruleForm.no)){
						return this.$message.error("请输入正确的手机号");
					}
				
					sendSms({phone:this.ruleForm.no}).then(res=>{
						if(res.code == 0){
							this.send = true;
							this.time = 60;
							this.changeTime();
						}else{
							return this.$message.error(res.msg);
						}
					})
				}else if(this.type == 2){//邮箱验证
					if(this.ruleForm.no == ""){
						return this.$message.error("请输入邮箱");
					}
					let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
					if(!reg.test(this.ruleForm.no)){
						return this.$message.error("请输入正确的邮箱");
					}
					sendEmail({email:this.ruleForm.no}).then(res=>{
						if(res.code == 0){
							this.send = true;
							this.time = 60;
							this.changeTime();
						}else{
							return this.$message.error(res.msg);
						}
					})
				}
				
			},
			changeTime(){
				if(this.timeFun != null){
					clearTimeout(this.timeFun);
				}
				this.timeFun = setTimeout(()=>{
					this.time--;
					if(this.time > 0){
						this.changeTime();
					}else{
						this.send = false;
					}
				},1000);
			},
			submitForm(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(this.type == 1){
							forgotPassword({
								mobile:this.ruleForm.no,
								verify:this.ruleForm.code,
								password:this.ruleForm.password
							}).then(res=>{
								if(res.code == 0){
									this.$message.success("重置成功，请登录");
									this.$router.push("/login");
								}else{
									return this.$message.error(res.msg);
								}
							})
						}else{
							forgotPassword({
								email:this.ruleForm.no,
								emailVerify:this.ruleForm.code,
								password:this.ruleForm.password
							}).then(res=>{
								if(res.code == 0){
									this.$message.success("重置成功，请登录");
									this.$router.push("/login");
								}else{
									return this.$message.error(res.msg);
								}
							})
						}
					} else {
						return false;
				  }
				});
			},
			tolast(){
				this.$router.push("/login");
			}
		}
	}
</script>

<style lang="scss" scoped>
	.login{
		width: 100%;
		height: 100vh;
		background-image: url(../assets/images/login/bg.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}
	.login-login{
		display: flex;
		justify-content: center;
		align-items: center;
		top: 50%;
		right: 15%;
		position: absolute;
		transform: translate(0%, -50%);
		.login-login-bg{
			position: relative;
			img{
				&:first-of-type{
					width: 55%;
					height: auto;
					margin-left: 10%;
				}
			}
		}
	}
	.login-model{
		width: 690px;
		background: #FFFFFF;
		border-radius: 20px;
		padding: 54px 0px 47px;
		.login-model-text{
			font-weight: bold;
			font-size: 24px;
			color: #16191E;
			line-height: 33px;
			text-align: center;
		}
	}
	.ruleForm{
		margin: 27px auto 0px;
		width: 370px;
		.pwd-see{
			width: 28px;
			height: 28px;
			position: absolute;
			top: 50%;
			margin-top: -14px;
			right: 18px;
			cursor: pointer;
		}
		.send-code{
			height: 22px;
			font-weight: 400;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			border-left: 1px solid #E3E3E3;
			width: 120px;
			text-align: center;
			position: absolute;
			top: 50%;
			right: 0px;
			margin-top: -11px;
			cursor: pointer;
		}
	}
	::v-deep .el-input__inner{
		width: 370px;
		height: 50px;
		background: #F9F9F9;
		border-radius: 6px;
		border: 0px;
	}
	::v-deep .el-form-item{
		margin-bottom: 18px;
	}
	.login-button{
		font-weight: 400;
		font-size: 14px;
		color: #3C4658;
		line-height: 20px;
		margin-top: 13px;
		display: flex;
		justify-content: space-between;
		div{
			cursor: pointer;
			font-weight: 400;
			font-size: 14px;
			color: #3C4658;
			line-height: 20px;
			text-align: right;
		}
	}
	.form-button{
		margin-top: 27px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		div{
			&:first-of-type{
				width: 166px;
				height: 50px;
				border-radius: 6px;
				border: 1px solid #FFB80F;
				font-weight: 400;
				font-size: 16px;
				color: #FFB80F;
				line-height: 50px;
				text-align: center;
				cursor: pointer;
			}
			&:last-of-type{
				width: 166px;
				height: 50px;
				border-radius: 6px;
				background: #FFB80F;
				border: 1px solid #FFB80F;
				font-weight: 400;
				font-size: 16px;
				color: #fff;
				line-height: 50px;
				text-align: center;
				cursor: pointer;
			}
		}
	}
	.forget-select{
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 370px;
		margin: 38px auto 0px;
		div{
			font-weight: bold;
			font-size: 18px;
			color: #16191E;
			line-height: 25px;
			position: relative;
			padding-bottom: 10px;
			cursor: pointer;
		}
		.active{
			font-weight: bold;
			color: #FFB80F;
			span{
				position: absolute;
				width: 22px;
				height: 4px;
				background: #FFB80F;
				border-radius: 2px;
				display: block;
				bottom: 0px;
				left: 50%;
				margin-left: -11px;
			}
		}
	}
</style>