import request from '@/utils/request'
import VueCookies from 'vue-cookies'


// 兑换积分
export function getCode(data) {
	data.userId = VueCookies.get('userId');
  return request({
    url: 'paper/redemptionHistory/get',
    method: 'post',
	data:data
  })
}

//获取积分任务
export function points() {
  return request({
    url: 'paper/user/points',
    method: 'post'
  })
}



//获取错题集
export function getWrongQuestion(data) {
	data.answerId = VueCookies.get('userId');
  return request({
    url: 'paper/answers/getWrongQuestion',
    method: 'get',
	params:data
  })
}


//获取我的考卷
export function getListByAnswer(data) {
	data.answerId = VueCookies.get('userId');
  return request({
    url: 'paper/newPaper/getListByAnswer',
    method: 'get',
	params:data
  })
}

