<template>
	<div class="view">
		<header class="header">
			<div class="header-content">
				<div class="header-content-title flex-start flex-center">
					<img src="@/assets/images/logo2.png"/>
					International test paper</div>
				<div class="top-user" @click="touser">
					<div class="top-user-avatar">
						<img v-if="info.avatar" :src="info.avatar"/>
						<img v-else src="@/assets/images/login/avatar.png"/>
					</div>
					<div class="top-user-name">
						<div>{{info.nickname}}</div>
						<div>{{info.mobile}}</div>
					</div>
				</div>
			</div>
		</header>
		<div class="view-content">
			<router-view/>
		</div>
	</div>
</template>

<script>
	import {getUserByToken} from "@/api/index"
	export default {
		data(){
			return {
				info:{}
			}
		},
		mounted() {
			getUserByToken().then(res=>{
				if(res.code == 0){
					this.info = res.data;
				}
			})
		},
		methods:{
			exit(){
				this.$refs.exitMsg.init("是否确定退出登录");
			},
			submitexit(){
				this.$cookies.set('userToken', "");
				this.$cookies.set("socketId", "");
				this.$router.replace({name:"login"});
			},
			touser(){
				this.$router.push("/center");
			}
		}
	}
</script>

<style lang="scss">
	.view-content{
		min-width: 1280px;
		margin: 0 auto;
	}
	.view{
		min-width: 1280px;
		.header{
			width: 100%;
			height: 94px;
			background: #FFB80F;
			position: fixed;
			top: 0px;
			left: 0px;
			right: 0px;
			z-index: 10;
		}
		.header-content{
			height: 94px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0px 15% 0px 5%;
			.header-content-title{
				img{
					width: 55px;
					height: 55px;
					margin-right: 7px;
				}
				font-weight: 900;
				font-size: 29px;
				color: #FFFFFF;
			}
			.top-user{
				display: flex;
				justify-content: flex-end;
				align-items: center;
				cursor: pointer;
				.top-user-avatar{
					width: 48px;
					height: 48px;
					background: rgba(255,255,255,0.6);
					padding: 2px;
					border-radius: 100%;
					img{
						width: 48px;
						height: 48px;
						border-radius: 100%;
					}
				}
				.top-user-name{
					margin-left: 6px;
					div{
						&:first-of-type{
							font-weight: 500;
							font-size: 16px;
							color: #FFFFFF;
							line-height: 22px;
						}
						&:last-of-type{
							font-weight: 500;
							font-size: 12px;
							color: #FFFFFF;
							line-height: 17px;
						}
					}
				}
			}
		}
	}
</style>