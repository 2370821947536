<template>
	<el-dialog title="" :visible.sync="dialogVisible" width="270px" custom-class="addModel" :center="true" :append-to-body="true" :show-close="false" :close-on-click-modal="false">
	 <div class="msg">
		 <div class="msg-title">温馨提示</div>
		 <div class="wechatcode-desc" v-if="type==2">{{msg}}</div>
		 <div class="wechatcode-desc" v-if="type==1">{{msg}}</div>
	 </div>
	  <span slot="footer" class="dialog-footer flex-midden flex-center">
		  <div class="dialog-footer-one" @click="dialogVisible=false">取消</div>
		  <div class="dialog-footer-two" v-if="type==2" @click="tocenter">去购买</div>
		  <div class="dialog-footer-two" v-if="type==1" @click="ok">确认</div>
	  </span>
	</el-dialog>
</template>

<script>
	export default {
		data(){
			return {
				dialogVisible:false,
				type:0,
				msg:"",
				value:{}
			}
		},
		methods:{
			init(type,msg,data){
				this.type = type;
				this.msg = msg;
				this.value = data;
				this.dialogVisible = true;
			},
			tocenter(){
				this.dialogVisible = false;
				this.$router.push("/center/member");
			},
			ok(){
				this.dialogVisible = false;
				this.$emit("ok", this.value);
			}
		}
	}
</script>


<style scoped lang="scss">
	.msg{
		.msg-title{
			font-weight: bold;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			text-align: center;
			padding: 0px 0px 11px;
		}
		.wechatcode-desc{
			font-weight: 400;
			font-size: 14px;
			color: #16191E;
			line-height: 20px;
			text-align: center;
			width: 200px;
			margin: 0 auto;
		}
	}
	.dialog-footer{
		.dialog-footer-one{
			width: 75px;
			height: 34px;
			border-radius: 4px;
			border: 1px solid #FFB80F;
			font-weight: 400;
			font-size: 14px;
			color: #FFB80F;
			line-height: 34px;
			text-align: center;
			margin: 0px 10px;
			cursor: pointer;
		}
		.dialog-footer-two{
			width: 75px;
			height: 34px;
			border: 1px solid #FFB80F;
			background: #FFB80F;
			border-radius: 6px;
			font-weight: 400;
			font-size: 16px;
			color: #FFFFFF;
			line-height: 34px;
			text-align: center;
			margin: 0px 10px;
			cursor: pointer;
		}
	}
	::v-deep .addModel{
		border-radius: 10px !important;
	}
	::v-deep .el-dialog__title{
		height: 0px;
	}
	::v-deep .el-dialog--center .el-dialog__body{
		padding: 0px;
	}
	
	::v-deep .el-input__inner{
		width: 370px;
		height: 50px;
		background: #F9F9F9;
		border-radius: 6px;
		font-weight: 400;
		font-size: 16px;
		color: #D1D2D4;
		line-height: 50px;
		text-align: left;
		border: 0px;
		margin: 0 auto;
		display: block;
	}
</style>