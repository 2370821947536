<template>
	<div class="report">
		<img class="report-close" @click="tolast" src="@/assets/images/close.png"/>
		<div class="report-title">答题报告</div>
		<div class="report-list flex-start flex-center flex-wrap">
			<div class="report-item" v-for="(item, index) in list" :key="index" v-if="item.level==1">
				<div class="report-item-title">{{item.name}}</div>
				<div class="report-item-info" :id="'main'+index"></div>
			</div>
		</div>
		<div class="report-list flex-start flex-center flex-wrap">
			<div class="report-item" v-for="(item, index) in list" :key="index" v-if="item.level==2">
				<div class="report-item-title">{{item.name}}</div>
				<div class="report-item-info" :id="'main'+index"></div>
			</div>
		</div>
		<div class="report-list flex-start flex-center flex-wrap">
			<div class="report-item" v-for="(item, index) in list" :key="index" v-if="item.level==3">
				<div class="report-item-title">{{item.name}}</div>
				<div class="report-item-info" :id="'main'+index"></div>
			</div>
		</div>
		
		<div style="height: 100px;"></div>
		<el-tooltip placement="top" effect="light" :visible-arrow="false">
			<div slot="content">
				<div class="show-button">
					<div @click="downloadReport">表单报告</div>
					<div>绘图报告</div>
				</div>
			</div>
			<div class="bottom">
				<div>下载报告</div>
			</div>
		</el-tooltip>
		
	</div>
</template>

<script>
	
	import {getPaperReport,downloadReport} from "@/api/index"
	import * as echarts from 'echarts';
	export default {
		data(){
			return {
				id:0,
				list:[]
			}
		},
		mounted() {
			this.id = this.$route.query.id || 0;
			getPaperReport({
				paperId:this.id
			}).then(res=>{
				if(res.code == 0){
					this.list = res.data;
					setTimeout(()=>{
						res.data.map((item,index)=>{
							var chartDom = document.getElementById('main'+index);
							var myChart = echarts.init(chartDom);
							var option;
							let x = [];
							let y = [];
							item.y.map(value=>{
								x.push(value.name);
								y.push(value.divide);
							})
							option = {
							  xAxis: {
							    type: 'category',
							    data: x
							  },
							  yAxis: {
							    type: 'value'
							  },
							  series: [
							    {
							      data: y,
							      type: 'bar',
									itemStyle: {
										normal: {
											//这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
											barBorderRadius:[40, 40, 0, 0]
										}
									}
							    }
							  ]
							};
							
							option && myChart.setOption(option);
						})
					},500);
					
				}else{
					this.$router.replace("/");
				}
			})
		},
		methods:{
			tolast(){
				this.$router.go(-1); 
			},
			downloadReport(){
				downloadReport({
					paperId:this.id
				}).then(res=>{
					const url = window.URL.createObjectURL(new Blob([res]))
					const link = document.createElement("a")
					link.href = url
					link.setAttribute("download",'报告.xlsx')//导出的格式一定要和接口返回的文件一致
					document.body.appendChild(link)
					link.click()
					window.URL.revokeObjectURL(url)
					document.body.removeChild(link)
				})
			}
		}
	}
</script>


<style lang="scss" scoped>
	@import "../assets/report.scss";
	
</style>