<template>
	<el-dialog title="" :visible.sync="dialogVisible" width="270px" custom-class="addModel" :center="true" :append-to-body="true" :show-close="false" :close-on-click-modal="false">
	 <div class="msg">
		 <div class="msg-title">查看考卷考卷</div>
		 <div class="wechatcode-desc flex-center flex-midden">
			<input type="text" placeholder="请输入密码" v-model="password"/>
		 </div>
	 </div>
	  <span slot="footer" class="dialog-footer flex-midden flex-center">
		  <div class="dialog-footer-one" @click="dialogVisible=false">取消</div>
		  <div class="dialog-footer-two" @click="submit">提交</div>
	  </span>
	</el-dialog>
</template>

<script>
	export default {
		data(){
			return {
				dialogVisible:false,
				password:"",
				info:{}
			}
		},
		methods:{
			init(info){
				this.info = info;
				this.password = "";
				this.dialogVisible = true;
			},
			submit(){
				if(!this.password){
					return this.$message.error("请输入密码");
				}
				this.dialogVisible = false;
				this.$emit("check",{info:this.info,password:this.password});
			}
		}
	}
</script>


<style scoped lang="scss">
	.msg{
		.msg-title{
			font-weight: bold;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			text-align: center;
			padding: 0px 0px 11px;
		}
		.wechatcode-desc{
			font-weight: 400;
			font-size: 14px;
			color: #16191E;
			line-height: 20px;
			text-align: center;
			margin-bottom: 15px;
			div{
				margin-right: 5px;
			}
		}
	}
	.dialog-footer{
		.dialog-footer-one{
			width: 75px;
			height: 34px;
			border-radius: 4px;
			border: 1px solid #FFB80F;
			font-weight: 400;
			font-size: 14px;
			color: #FFB80F;
			line-height: 34px;
			text-align: center;
			margin: 0px 10px;
			cursor: pointer;
		}
		.dialog-footer-two{
			width: 75px;
			height: 34px;
			border: 1px solid #FFB80F;
			background: #FFB80F;
			border-radius: 6px;
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			line-height: 34px;
			text-align: center;
			margin: 0px 10px;
			cursor: pointer;
		}
	}
	::v-deep .addModel{
		border-radius: 10px !important;
	}
	::v-deep .el-dialog__title{
		height: 0px;
	}
	::v-deep .el-dialog--center .el-dialog__body{
		padding: 0px;
	}
	input{
		width: 225px;
		height: 34px;
		background: #F6F6F6;
		border-radius: 4px;
		border: 1px solid #F1F1F1;
		font-weight: 400;
		font-size: 14px;
		color: #000;
		line-height: 34px;
		text-align: center;
	}
</style>