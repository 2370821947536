<template>
	<div class="index">
		<div class="index-notice flex-start flex-center">
			<img src="@/assets/images/laba.png"/>
			<div class="index-notice-text">
				<div class="text flex-start">
					<span v-for="item in list" :key="item.id">{{item.name}}</span>
				</div>
			</div>
		</div>
		
		<div class="index-menu flex-between flex-center flex-wrap">
			<router-link to="/pastpaper" class="index-menu-item">
				<img src="@/assets/images/index/1.png"/>
				<div>PastPaper题库</div>
			</router-link>
			<router-link to="/search" class="index-menu-item" style="background-color: #FFF2F1;">
				<img src="@/assets/images/index/2.png"/>
				<div>试题搜索</div>
			</router-link>
			<router-link to="/answer" class="index-menu-item" style="background-color: #EDFAFE;">
				<img src="@/assets/images/index/3.png"/>
				<div>在线答题</div>
			</router-link>
			<router-link to="/word" class="index-menu-item" style="background-color: #FFF7EB;">
				<img src="@/assets/images/index/4.png"/>
				<div>学科单词</div>
			</router-link>
			<router-link to="/question" class="index-menu-item" style="background-color: #EEFBFF;">
				<img src="@/assets/images/index/5.png"/>
				<div>专项题库</div>
			</router-link>
			<router-link to="/note" class="index-menu-item" style="background-color: #FFF5ED;">
				<img src="@/assets/images/index/6.png"/>
				<div>学霸笔记</div>
			</router-link>
		</div>
		<div class="index-action flex-between flex-center">
			<router-link to="/center/member" class="index-action-item">
				<img src="@/assets/images/index/7.png"/>
				<div>充值会员</div>
			</router-link>
			<router-link to="/center/score" class="index-action-item">
				<img src="@/assets/images/index/8.png"/>
				<div>积分任务</div>
			</router-link>
			<div class="index-action-item" @click="showwechat">
				<img src="@/assets/images/index/9.png"/>
				<div>加入社群</div>
			</div>
		</div>
		<wechatcode ref="wechatcode"></wechatcode>
	</div>
</template>

<script>
	import wechatcode from "@/components/wechatcode.vue"
	import {broadcast} from "@/api/index"
	export default {
		components:{
			wechatcode
		},
		data(){
			return {
				list:[]
			}
		},
		mounted() {
			broadcast().then(res=>{
				if(res.code == 0){
					this.list = res.data;
				}
			})
		},
		methods:{
			showwechat(){
				this.$refs.wechatcode.init();
			}
		}
	}
</script>

<style scoped lang="scss">
	.index{
		width: 100%;
		height: 100vh;
		background-image: url(../assets/images/index/bg.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		padding-top: 135px;
		box-sizing: border-box;
	}
	.index-notice{
		width: 1200px;
		height: 44px;
		background: #F9F9F9;
		border-radius: 29px;
		border-radius: 29px;
		padding: 0px 17px;
		box-sizing: border-box;
		margin: 0 auto;
		img{
			width: 20px;
			height: 21px;
			margin-right: 10px;
		}
		.index-notice-text{
			white-space: nowrap;
			overflow: hidden;
			box-sizing: border-box;
			span{
				font-weight: 400;
				font-size: 14px;
				color: #16191E;
				line-height: 20px;
				margin-right: 48px;
			}
		}
	}
	.index-menu{
		width: 958px;
		margin: 20px auto 0px;
		.index-menu-item{
			width: 291px;
			height: 167px;
			background: #EEFFFC;
			border-radius: 25px;
			text-align: center;
			padding-top: 41px;
			margin: 10px 0px;
			cursor: pointer;
			text-decoration: none;
			img{
				width: 94px;
				height: 94px;
			}
			div{
				font-weight: 400;
				font-size: 14px;
				color: #040404;
				line-height: 20px;
				margin-top: 23px;
			}
		}
	}
	.index-action{
		width: 958px;
		margin: 20px auto 0px;
		.index-action-item{
			text-decoration: none;
			width: 291px;
			height: 118px;
			background: #FAFAFA;
			border-radius: 25px;
			text-align: center;
			padding-top: 31px;
			box-sizing: border-box;
			cursor: pointer;
			img{
				width: 40px;
				height: 35px;
			}
			div{
				font-weight: 400;
				font-size: 14px;
				color: #040404;
				line-height: 20px;
				margin-top: 7px;
			}
		}
	}
</style>