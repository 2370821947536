import { render, staticRenderFns } from "./question.vue?vue&type=template&id=6ffe7f8c&scoped=true"
import script from "./question.vue?vue&type=script&lang=js"
export * from "./question.vue?vue&type=script&lang=js"
import style0 from "./question.vue?vue&type=style&index=0&id=6ffe7f8c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ffe7f8c",
  null
  
)

export default component.exports